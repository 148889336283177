<template>
	<div
		class="toggle-sidebar-button d-lg-none"
		:class="[sidebarShown ? 'show-sidebar' : '']"
	>
		<button
			class="button d-flex align-items-center justify-content-center"
			@click="toggleSidebar(!showSidebar)"
		>
			<div v-if="sidebarShown" class="pi pi-times"></div>
			<div v-else class="pi pi-bars"></div>
		</button>
	</div>
</template>

<script>
	import { mapMutations, mapState } from "vuex";

	export default {
		data() {
			return {
				sidebarShown: false,
			};
		},
		computed: {
			...mapState(["showSidebar"]),
		},
		methods: {
			...mapMutations(["toggleSidebar"]),
		},
		watch: {
			showSidebar() {
				this.sidebarShown = this.showSidebar;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.toggle-sidebar-button {
		position: fixed;
		bottom: 1rem;
		right: 1rem;
		z-index: 10;
		border-radius: 100%;
		box-shadow: $smooth-box-shadow;
		transition: $transition;

		.button {
			border: 0;
			border-radius: 10rem;
			width: 3.5rem;
			aspect-ratio: 1;
			background-color: $secondary;
			color: white;
		}

		&.show-sidebar {
			right: calc(100% - 1rem);
			transform: translateX(100%);
		}

		:deep(.p-button-label) {
			display: none;
		}
	}
</style>
