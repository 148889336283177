<template>
	<Sidebar />
	<Navbar />
	<Header v-if="showHeader" />
	<Loading />
	<GoTopButton />
	<AcceptCookies />
	<Toast />

	<router-view />

	<Footer />
	<ToggleSidebarButton />
	<GoCatalogButton />
</template>

<script>
	// Panels
	import Sidebar from "@/components/web/sections/Sidebar.vue";
	import Navbar from "@/components/web/sections/Navbar.vue";
	import ToggleSidebarButton from "@/components/web/partials/ToggleSidebarButton.vue";
	import GoCatalogButton from "@/components/web/partials/GoCatalogButton.vue";
	import Loading from "@/components/partials/Loading.vue";
	import GoTopButton from "@/components/web/partials/GoTopButton.vue";
	import AcceptCookies from "@/components/web/partials/AcceptCookies.vue";
	import Toast from "primevue/toast";

	// Sections
	import Header from "@/components/web/sections/Header.vue";
	import Footer from "@/components/web/sections/Footer.vue";

	import { mapState, mapMutations, mapActions } from "vuex";

	export default {
		components: {
			// Panels
			Sidebar,
			Navbar,
			ToggleSidebarButton,
			GoCatalogButton,
			Loading,
			GoTopButton,
			AcceptCookies,
			Toast,

			// Sections
			Header,
			Footer,
		},
		computed: {
			...mapState([
				"errorToast",
				"successToast",
				"warningToast",
				"showSidebar",
				"userLogged",
			]),
			showHeader() {
				return this.$route.name === "home";
			},
		},
		methods: {
			...mapActions(["setFormAccessToken"]),
			...mapMutations([
				"changeSuccessToast",
				"changeErrorToast",
				"changeWarningToast",
				"toggleSidebar",
				"changeUserLogged",
			]),
		},
		watch: {
			$route() {
				this.$helper.scrollToStart();
			},
			successToast() {
				if (this.successToast != null) {
					this.$toast.add({
						severity: "success",
						summary: "Acción realizada",
						detail:
							this.successToast == true
								? "Operación realizada con éxito"
								: this.successToast,
						life: 3000,
					});
				}

				this.changeSuccessToast(null);
			},
			errorToast() {
				if (this.errorToast != null) {
					this.$toast.add({
						severity: "error",
						summary: "Error",
						detail:
							this.errorToast == true
								? "Ha ocurrido un error"
								: this.errorToast,
						life: 3000,
					});
				}

				this.changeErrorToast(null);
			},
			warningToast() {
				if (this.warningToast != null) {
					this.$toast.add({
						severity: "warn",
						summary: "Advertencia",
						detail:
							this.warningToast == true
								? "Ha ocurrido un error"
								: this.warningToast,
						life: 3000,
					});
				}

				this.changeWarningToast(null);
			},
		},
	};
</script>
