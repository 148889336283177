<template>
	<Welcome class="custom-section-padding" />
	<Catalog class="custom-section-padding" />
	<Banner class="custom-section-padding" />
	<AboutUs class="custom-section-padding" />
</template>

<script>
	import Welcome from "@/components/web/sections/Welcome.vue";
	import Catalog from "@/components/web/sections/Catalog.vue";
	import Banner from "@/components/web/sections/Banner.vue";
	import AboutUs from "@/components/web/sections/AboutUs.vue";

	export default {
		components: { Welcome, Catalog, Banner, AboutUs },
	};
</script>
