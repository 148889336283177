<template>
	<div class="custom-section-padding custom-container mx-auto">
		<SectionTitle title="Empresa" />
		<div class="row mt-5">
			<div class="col-12 col-md-6">
				<div class="h2 mb-4">Descubra nuestra historia</div>
				<p class="text-muted">
					Desde 1946, Papelería e Imprenta Rivero sigue manteniéndose
					fiel a sus objetivos de mantener la calidad y el trato
					personalizado a sus clientes.
					<br />
					<br />
					Ello se consigue con una dedicación plena y un gusto
					exquisito por nuestro trabajo, tratando a cada uno de
					nuestros clientes como si fuera único, dándole el
					asesoramiento y el servicio exacto que nos demanda en cada
					caso, lo que nos hace merecedor de su confianza.
					<br />
					<br />
					En nuestras instalaciones podrá encontrar un amplio catálogo
					de productos de papelería, desde los artículos clásicos de
					escritura, papelería y archivo hasta las últimas tendencias
					de este sector para sorprender con un detalle o un regalo a
					alguien, teniendo la tranquilidad de acertar siempre en su
					elección.
					<br />
					<br />
					Nuestro interés principal es darle al cliente un servicio
					integral que de respuestas a sus necesidades, sin tener que
					contactar con múltiples proveedores, teniéndo todos los
					servicios que pudiera demandar al alcance de su mano.
				</p>
			</div>
			<div class="col-12 col-md-6">
				<img
					class="rounded image"
					src="@/assets/images/company/foto2.jpg"
				/>
			</div>
			<div class="col-12 col-md-6 mt-3 mt-md-4">
				<div class="aspect-ratio">
					<img
						class="rounded image"
						src="@/assets/images/company/foto1.jpg"
					/>
				</div>
			</div>
			<div class="col-12 col-md-6 mt-3 mt-md-4">
				<div class="aspect-ratio">
					<img
						class="rounded image"
						src="@/assets/images/company/foto3.jpg"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SectionTitle from "@/components/web/partials/SectionTitle.vue";

	export default {
		components: {
			SectionTitle,
		},
	};
</script>

<style lang="scss" scoped>
	.image {
		width: 100%;
	}

	.aspect-ratio {
		aspect-ratio: 3/2;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
</style>
