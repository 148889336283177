<template>
	<div class="custom-section-padding custom-container mx-auto">
		<h3>Aviso legal</h3>

		<p><b>Identidad del titular de la web</b></p>

		<p>
			Rivero SL con CIF B-35218767, con domicilio en C/ General Vives, 60
			35007 - Las Palmas de G.C., y correo electrónico
			<Email color="primary" :showIcon="false" :showText="true" />
			es Titular de esta web, con dominio http://www.riverosl.es
		</p>

		<p><b>Aceptación de las condiciones de uso</b></p>

		<p>
			La utilización de este sitio Web atribuye al navegante, sea persona
			física o jurídica, la condición de USUARIO, lo cual implica la
			adhesión a los términos y condiciones que a continuación se indican.
			A todos los efectos, se contemplarán los términos y condiciones en
			la versión publicada en el momento en que se produce el acceso. Se
			recomienda al USUARIO leer atentamente los términos y condiciones
			que a continuación se detallan antes de hacer uso de los servicios
			ofertados. Así mismo, el acceso a determinados servicios a través de
			este sitio Web puede encontrarse sometido a ciertas condiciones
			particulares propias que, según los casos, sustituyen, completan y/o
			modifican estas condiciones generales. Por tanto, con anterioridad
			al acceso y/o utilización de nuestros servicios, el USUARIO debe
			leer atentamente también las correspondientes condiciones
			particulares. En caso de no aceptar las condiciones de uso, deberá
			abstenerse de acceder y utilizar los servicios contenidos en esta
			Web. Las presentes condiciones de uso regulan el uso genérico de la
			Web por parte del USUARIO que tiene la posibilidad de visualizarla e
			imprimirlas. El Titular de la Web se reserva el derecho a modificar
			o suprimir en cualquier momento y sin previo aviso los contenidos,
			servicios e informaciones que se encuentran en esta Web, así como a
			limitar o cancelar los términos y condiciones aplicables a la Web.
		</p>

		<p><b>Propiedad intelectual e industrial</b></p>

		<p>
			El contenido de esta Web se encuentra protegido por las Leyes sobre
			Propiedad Intelectual e Industrial. El sitio web, incluyendo a
			título enunciativo pero no limitativo su programación, edición,
			compilación y demás elementos necesarios para su funcionamiento, los
			diseños, logotipos, texto y/o gráficos son propiedad del responsable
			de la Web o en su caso dispone de licencia o autorización expresa
			por parte de los autores. Independientemente de la finalidad para la
			que fueran destinados, la reproducción total o parcial, uso,
			explotación, distribución y comercialización, requiere en todo caso
			de la autorización escrita previa por parte del Titular de la Web.
			La infracción de cualquiera de los citados derechos puede constituir
			una vulneración de las presentes disposiciones, así como un delito
			castigado de acuerdo con los artículos 270 y siguientes del Código
			Penal. Los diseños, logotipos, texto y/o gráficos ajenos al
			responsable de la Web y que pudieran aparecer en el sitio web,
			pertenecen a sus respectivos propietarios, siendo ellos mismos
			responsables de cualquier posible controversia que pudiera
			suscitarse respecto a los mismos. En todo caso, se cuenta con la
			autorización expresa y previa por parte de los mismos. Las
			reclamaciones que pudieran interponerse por los USUARIOS en relación
			con posibles incumplimientos de los derechos de propiedad
			intelectual o industrial sobre cualesquiera de los contenidos de
			este sitio Web deberán dirigirse al correo electrónico
			<Email color="primary" />.
		</p>

		<p><b>Acceseo a la web</b></p>

		<p>
			Nuestra Web puede ser visitada por cualquier USUARIO de forma libre
			y gratuita. Los datos de Carácter Personal que nos facilite serán
			siempre tratados de conformidad con lo dispuesto en la normativa
			vigente.
		</p>

		<p><b>Uso correcto del sitio web</b></p>

		<p>
			El USUARIO se compromete a utilizar la Web, los contenidos y los
			servicios de acuerdo con la Ley, con las buenas costumbres y el
			orden público. El USUARIO se obliga y compromete a:
		</p>

		<ul>
			<li>
				No utilizar la Web o los servicios que se prestan a través de
				ella con finalidades o efectos ilícitos o contrarios al
				contenido de este Aviso Legal que lesionen los intereses o
				derechos de terceros, o que de cualquier forma pueda dañar,
				inutilizar o deteriorar la Web o sus servicios o impedir un uso
				satisfactorio de la Web a otros usuarios.
			</li>
			<li>
				No destruir, alterar, inutilizar o, de cualquier otra forma,
				dañar los datos, los programas o los documentos electrónicos y
				otros que se encuentren en la Web.
			</li>
			<li>
				No introducir programas, virus, macroinstrucciones,
				miniaplicaciones o cualquier otro dispositivo lógico o secuencia
				de caracteres que causen o puedan causar cualquier tipo de
				alteración en los sistemas informáticos del responsable del la
				Web o de terceros.
			</li>
			<li>
				No hacer mal uso de informaciones, mensajes, gráficos, dibujos,
				archivos de sonido y/o imagen, fotografías, grabaciones,
				software y, en general, cualquier clase de material accesible a
				través de la Web o de los servicios que ofrece.
			</li>
			<li>
				No introducir actuaciones, actitudes o ideas discriminatorias
				por razón de sexo, raza, religión, creencias, edad o condición.
			</li>
			<li>
				Asimismo, el responsable de la web se reserva el derecho a
				denegar o retirar el acceso a la Web y/o a los servicios en
				cualquier momento y sin necesidad de previo aviso a aquellos
				USUARIOS que incumplan estas condiciones generales.
			</li>
		</ul>

		<p><b>Régimen de responsabilidad</b></p>

		<p>
			El responsable de la web no se hará responsable, directa ni
			subsidiariamente de:
		</p>

		<ul>
			<li>
				La calidad del servicio, la velocidad de acceso, el correcto
				funcionamiento ni la disponibilidad ni continuidad de
				funcionamiento de la Web.
			</li>
			<li>
				Que existan interrupciones del servicio, demoras, errores, mal
				funcionamiento del mismo y, en general, demás inconvenientes que
				tengan su origen en causa que escapan del control del Titular de
				la Web.
			</li>
			<li>
				Una actuación dolosa o culposa del USUARIO y/o tenga por origen
				causas de fuerza mayor. En cualquier caso, sea cual fuere su
				causa.
			</li>
			<li>
				Daños directos o indirectos, daño emergente y/o por lucro
				cesante.
			</li>
			<li>
				Los contenidos y opiniones de terceros ni de la información
				contenida en páginas Web de terceros a las que se pueda acceder
				por Enlaces o buscadores del sitio Web.
			</li>
			<li>
				Los daños que puedan causarse en los equipos de los USUARIOS por
				posibles virus informáticos contraídos a causa de su navegación
				en el sitio Web o por cualquier otro daño derivado de esa
				navegación.
			</li>
			<li>
				Del incumplimiento de la Ley, la moral y las buenas costumbres
				generalmente aceptadas o el orden público como consecuencia de
				la transmisión, difusión, almacenamiento, puesta a disposición,
				recepción, obtención o acceso a los contenidos.
			</li>
			<li>
				Los vicios, y defectos de toda clase de los contenidos
				transmitidos, difundidos, almacenados o puestos a disposición,
				la falta de actualización o de exactitud de los mismos, ni de su
				calidad científica en su caso.
			</li>
			<li>
				Así mismo, el Titular no garantiza que la Web y el servidor
				estén libres de virus y no se hace responsable de los daños
				causados por el acceso a la Web o por la imposibilidad de
				acceder.
			</li>
			<li>
				El Titular tendrá derecho, sin que exista indemnización alguna
				al USUARIO por estos conceptos, a suspender temporalmente los
				servicios y contenidos del sitio Web para efectuar operaciones
				de mantenimiento, mejora o reparación de los mismos.
			</li>
		</ul>

		<p><b>Protección de datos</b></p>

		<p>
			De conformidad con lo establecido en la Ley Orgánica 3/2018 de
			Protección de Datos Personales y garantía de los derechos digitales,
			le informamos que la información que facilite el Usuario será
			incorporada al sistema de tratamiento titularidad del Responsable
			con con la finalidad de poder facilitar, agilizar y cumplir los
			compromisos establecidos entre ambas partes.
		</p>

		<p>
			Así mismo, el Titular y Responsable informa que los datos serán
			conservados durante el plazo estrictamente necesario para cumplir
			con los preceptos mencionados con anterioridad. Mientras Vd. no
			comunique lo contrario, entenderemos que sus datos no han sido
			modificados, que usted se compromete a notificarnos cualquier
			variación y que tenemos su consentimiento para utilizarlos para las
			finalidades mencionadas.
		</p>

		<p>
			Se le informa que procederá a tratar los datos de manera lícita,
			leal, transparente, adecuada, pertinente, limitada, exacta y
			actualizada. Es por ello que el Responsable se compromete a adoptar
			todas las medidas razonables para que estos se supriman o
			rectifiquen sin dilación cuando sean inexactos. De acuerdo con los
			derechos que le confiere el la normativa vigente en materia de
			Protección de Datos, el Usuario podrá ejercer los derechos de
			acceso, rectificación, limitación de tratamiento, supresión,
			portabilidad y oposición al tratamiento de sus datos de carácter
			personal así como del consentimiento prestado para el tratamiento de
			los mismos, debiendo dirigir su petición por escrito e
			identificánose suficientemente a la dirección citada en el punto 1.
		</p>

		<p>
			Así mismo, también podrá dirigirse a la Autoridad de Control para
			presentar la reclamación que considere oportuna.
		</p>

		<p><b>Publicidad</b></p>

		<p>
			Conforme a lo regulado por la Ley 34/2002, de 11 de julio, de
			Servicios de la Sociedad de la Información y Comercio Electrónico
			(LSSICE) la política respecto al correo electrónico se centra en
			remitir únicamente comunicaciones que el USUARIO haya solicitado
			recibir habiendo trásladado de modo voluntario y expreso su
			consentimiento. Si Usuario quiere desea recibir estas
			comunicaciones, deberá dirigirse su petición por escrito e
			identificánose suficientemente a la dirección electronica
			<Email color="primary" />.
		</p>

		<p><b>Legislación aplicable y competencia judicial</b></p>

		<p>
			Las presentes condiciones están redactadas en español, y se
			encuentran sometidas a la legislación española vigente. Para toda
			cuestión litigiosa o que incumba a éste sitio Web serán competentes
			los Juzgados y Tribunales de Las Palmas, España.
		</p>
	</div>
</template>

<script>
	import Email from "@/components/web/partials/Email.vue";

	export default {
		components: {
			Email,
		},
	};
</script>
