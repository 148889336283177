<template>
	<a
		:class="[`text-${color}`]"
		class="d-flex align-items-center gap-2 text-decoration-none"
		:href="[`tel:${phone}`]"
		target="_blank"
		rel="noopener noreferrer"
	>
		<i
			v-if="showIcon"
			role="button"
			style="font-size: 1.2rem"
			class="pi pi-phone"
		></i>
		<div v-if="showText">{{ phone }}</div>
	</a>
</template>

<script>
	export default {
		props: {
			showIcon: {
				type: Boolean,
				default: true,
			},
			showText: {
				type: Boolean,
				default: false,
			},
			color: {
				type: String,
				default: "white",
			},
			phoneNumber: {
				type: String,
				default: "phone-1",
			},
		},
		computed: {
			phone() {
				switch (this.phoneNumber) {
					case "phone-1":
						return "928 26 48 22";
					case "phone-2":
						return "928 27 33 82";
					case "phone-3":
						return "928 27 37 21";
					default:
						return "";
				}
			},
		},
	};
</script>
