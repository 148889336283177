<template>
	<div class="custom-section-padding custom-container mx-auto">
		<h3>Aviso legal</h3>

		<p><b>¿Qué son las cookies?</b></p>

		<p>
			Las Cookies son pequeños archivos que se instalan en el terminal del
			USUARIO al visitar un sitio web con la finalidad de almacenar datos
			que podrán ser actualizados y recuperados por el responsable de su
			instalación. Si el USUARIO no se registra ni deja información
			personal en el sitio, el servidor tendrá conocimiento de que alguien
			con esa cookie regresó al sitio web pero le será imposible asociar
			la navegación a un USUARIO concreto e identificado.
		</p>

		<p><b>¿Qué clases de cookies existen?</b></p>

		<p>
			Las Cookies, en función de su caducidad, pueden clasificarse en
			Cookies de sesión o permanentes. Las primeras expiran cuando el
			usuario cierra el navegador mientras que las segundas permanecen por
			un tiempo superior durante el cual los datos siguen almacenados en
			el terminal y pueden ser accedidos y tratados.
		</p>

		<p>
			También se pueden distinguir según la entidad que gestione el equipo
			o dominio desde donde se envían las Cookies y se traten los datos
			que se obtengan. En este caso, se clasifican como:
		</p>

		<ul>
			<li>
				Cookies propias: aquellas que se envían al equipo terminal del
				Usuario desde un equipo o dominio gestionado por el Titular de
				la Web y desde el que se presta el servicio solicitado por el
				propio Usuario.
			</li>
			<li>
				Cookies de tercero: aquellas que son enviadas al equipo terminal
				del Usuario desde un equipo o domino que no es gestionado por el
				Titular de la Web, sino por otra entidad que trata los datos
				obtenidos a través de las Cookies.
			</li>
		</ul>

		<p>
			Las Cookies también se pueden clasificar en función de su objetivo y
			por tanto podemos hablar de:
		</p>

		<ul>
			<li>
				Cookies Técnicas: permiten al Usuario la navegación a través de
				una página web, plataforma o aplicación y la utilización de las
				diferentes opciones o servicios que en ella existan como, por
				ejemplo, controlar el tráfico y la comunicación de datos,
				identificar la sesión o realizar el proceso de compra de un
				pedido.
			</li>
			<li>
				Cookies de Personalización: permiten al Usuario acceder al
				servicio con algunas características de carácter general
				predefinidas en función de una serie de criterios en el terminal
				del usuario como por ejemplo el idioma o el tipo de navegador.
			</li>
			<li>
				Cookies Analíticas: permiten al responsable de las mismas, el
				seguimiento y análisis del comportamiento de los usuarios de los
				sitios web a los que están vinculadas. La información recabada
				se utiliza en la medición de la actividad de los sitios web,
				aplicación o plataforma y para la elaboración de perfiles de
				navegación de los usuarios de dichos sitios, aplicaciones y
				plataformas, con el fin de introducir mejoras en función del
				análisis de los datos que hacen los usuarios del servicio.
			</li>
		</ul>

		<p><b>¿Cómo desactivar o eliminar cookies?</b></p>

		<p>
			El Usuario podrá configurar su navegador para que no se instalen
			cookies o para recibir un aviso en pantalla cada vez que una cookie
			quiera instalarse, pudiendo decidir en cada momento si la quiere
			aceptar o no. Igualmente el usuario podrá decidir posteriormente
			eliminar las cookies que tenga instaladas cambiando la configuración
			de su navegador o eliminándolas manualmente a través del mismo.
			Tenga en cuenta que la eliminación de las cookies puede suponer una
			disminución en la calidad o en las funcionalidades de la página web.
			Para realizar estás operaciones, debe seguir las indicaciones del
			navegador que Vd. utilice:
		</p>

		<ul>
			<li>
				<a
					href="https://support.google.com/chrome/answer/95647?hl=es"
					target="_blank"
					rel="noopener noreferrer"
					>Google Chrome</a
				>
			</li>
			<li>
				<a
					href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-"
					target="_blank"
					rel="noopener noreferrer"
					>Mozilla Firefox</a
				>
			</li>
			<li>
				<a
					href="https://support.apple.com/es-es/HT201265"
					target="_blank"
					rel="noopener noreferrer"
					>Safari</a
				>
			</li>
			<li>
				<a
					href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer"
					target="_blank"
					rel="noopener noreferrer"
					>Internet Explorer</a
				>
			</li>
		</ul>

		<p><b>¿Qué cookies usa este sitio web?</b></p>

		<table class="table">
			<thead>
				<tr>
					<th>Nombre</th>
					<th>Caducidad</th>
					<th>Finalidad</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td>_ga</td>
					<td>2 años</td>
					<td>Se usa para distinguir a los usuarios.</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
