import Consts from "../consts";

const defaults = [
	"customers",
	"users",
	"operationOrders",
	"alerts",
	"onGoinAlerts",
];

const state = {
	baseURL: Consts.defaultApiRoute,
	showSidebar: false,
	userLogged: null,

	// Datatable defaults
	datatableDefaults: {
		noResults: "No hay resultados",
		lazy: true,
		loading: true,
		paginator: true,
		rows: 5,
		rowsPerPageOptions: [5, 10, 20],
		rowHover: true,
		scrollable: true,
		scrollHeight: "600px",
		currentPage: 0,
		paginatorTemplate:
			"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
		currentPageReportTemplate:
			"Mostrando del {first} al {last} de un total de {totalRecords} registros",
		responsiveLayout: "stack",
		breakpoint: "960px",
	},

	// Dialogs defaults
	dialogDefaults: {
		modal: true,
		closeOnEscape: true,
		dismissableMask: true,
	},

	// Page routes
	pageRoutes: [
		{
			label: "Inicio",
			href: "/",
		},
		{
			label: "Servicios",
			href: "/servicios",
		},
		{
			label: "Contacto",
			href: "/contacto",
		},
		{
			label: "Empresa",
			href: "/empresa",
		},
	],

	// Form defaults
	statusOptions: [
		{
			name: "No abierta",
		},
		{
			name: "Abierta",
		},
	],

	// Customers
	selectedCustomer: null,

	// Operation orders
	operationOrderSaved: false,
};

for (const item of defaults) {
	state[item] = {
		// Table
		list: [],
		listTotal: 0,
		currentPage: 0,
		rows: 5,

		// Form
		register: null,
		dialog: false,
		dialogMode: "show",
	};
}

export default state;
