<template>
	<Button
		class="go-top-button d-none d-xl-block"
		:class="{ show: showButton }"
		icon="pi pi-chevron-up"
		rounded
		@click="scrollToTop"
	/>
</template>

<script>
	export default {
		data() {
			return {
				showButton: false,
			};
		},
		mounted() {
			window.addEventListener("scroll", this.onScroll);
		},
		methods: {
			onScroll() {
				this.showButton = window.scrollY > 100;
			},
			scrollToTop() {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.go-top-button {
		position: fixed;
		bottom: 20px;
		right: 20px;
		visibility: hidden;
		opacity: 0;
		transition: $transition;
		z-index: 50;
		box-shadow: $smooth-box-shadow;

		&.show {
			visibility: visible;
			opacity: 1;

			&:enabled:hover {
				background-color: darken($primary, 10%) !important;
			}
		}

		:deep(.p-button-label) {
			display: none;
		}
	}
</style>
