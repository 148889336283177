<template>
	<div v-if="data != null">
		{{ data }}
	</div>
	<div v-else class="text-muted">Sin información</div>
</template>

<script>
	export default {
		props: {
			data: {
				required: true,
			},
		},
	};
</script>
