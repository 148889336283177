<template>
	<nav class="d-flex justify-content-center" :class="{ scrolled: scrolled }">
		<div
			class="custom-container d-flex justify-content-center justify-content-lg-between align-items-center py-3"
		>
			<!-- Logo -->
			<router-link to="/">
				<img src="@/assets/images/logotipo.png" height="80" />
			</router-link>

			<!-- Links -->
			<div class="d-none d-lg-flex align-items-center gap-5">
				<router-link
					v-for="(pageRoute, index) in pageRoutes"
					:key="index"
					:to="pageRoute.href"
					class="link text-secondary"
				>
					{{ pageRoute.label }}
				</router-link>
				<a href="https://catalogo.riverosl.es/" target="_blank">
					<Button label="Visitar catálogo" />
				</a>
			</div>
		</div>
	</nav>
</template>

<script>
	import { mapState } from "vuex";
	import Button from "primevue/button";

	export default {
		components: {
			Button,
		},
		computed: {
			...mapState(["pageRoutes"]),
		},
		data: () => ({
			scrolled: false,
		}),
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
		beforeUnmount() {
			window.removeEventListener("scroll", this.handleScroll);
		},
		methods: {
			handleScroll() {
				this.scrolled = window.scrollY > 0;
			},
		},
	};
</script>

<style lang="scss" scoped>
	nav {
		position: fixed;
		z-index: 5;
		width: 100%;
		background-color: white;
		backdrop-filter: blur(3px);
		top: 0;
		left: 0;
		right: 0;
		transition: $transition;
		box-shadow: $smooth-box-shadow;

		.link {
			text-decoration: none;
			transition: $transition;
			font-size: 1.2rem;

			&:hover {
				color: $primary !important;
			}
		}

		@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			img {
				height: 60px;
			}
		}
	}
</style>
