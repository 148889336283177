<template>
	<div class="custom-container mx-auto">
		<div class="about-us d-flex flex-wrap">
			<div class="col-12 col-md-6">
				<div
					class="left-section d-flex justify-content-center align-items-start flex-column h-100 p-sm-2 p-md-5"
				>
					<!-- <div class="subtitle text-uppercase text-muted fw-bold">
						Descubre nuestra historia
					</div> -->
					<div class="h2 mb-4">Sobre nosotros</div>
					<p class="text-small mb-0">
						Desde 1946, Papelería e Imprenta Rivero sigue
						manteniéndose fiel a sus objetivos de mantener la
						calidad y el trato personalizado a sus clientes.
						<br />
						<br />
						Ello se consigue con una dedicación plena y un gusto
						exquisito por nuestro trabajo, tratando a cada uno de
						nuestros clientes como si fuera único, dándole el
						asesoramiento y el servicio exacto que nos demanda en
						cada caso, lo que nos hace merecedor de su confianza.
						<br />
						<br />
						En nuestras instalaciones podrá encontrar un amplio
						catálogo de productos de papelería, desde los artículos
						clásicos de escritura, papelería y archivo hasta las
						últimas tendencias de este sector para sorprender con un
						detalle o un regalo a alguien, teniendo la tranquilidad
						de acertar siempre en su elección.
						<br />
						<br />
						Nuestro interés principal es darle al cliente un
						servicio integral que de respuestas a sus necesidades,
						sin tener que contactar con múltiples proveedores,
						teniéndo todos los servicios que pudiera demandar al
						alcance de su mano.
					</p>
				</div>
			</div>
			<div class="col-12 col-md-6 mt-4 mt-md-0">
				<div class="right-section rounded">
					<img
						class="anniversary"
						src="@/assets/images/company/75-aniversario.png"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.about-us {
		.right-section {
			width: 100%;
			height: 100%;
			background-image: url("@/assets/images/company/foto4.jpg");
			background-size: cover;
			background-position: center;
			aspect-ratio: 1;
			position: relative;

			.anniversary {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 20rem;
				max-width: 50%;
			}
		}

		.left-section {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 3rem;
				aspect-ratio: 3/4;
				border-top: 1px solid $primary;
				border-left: 1px solid $primary;
				z-index: 1;
			}

			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 3rem;
				aspect-ratio: 4/3;
				border-bottom: 1px solid $primary;
				border-left: 1px solid $primary;
				z-index: 1;
			}

			.subtitle {
				letter-spacing: 0.1rem;
				word-spacing: 0.2rem;
			}

			p {
				font-size: 0.9rem;
			}
		}

		@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			.left-section {
				&::after {
					display: none;
				}

				&::before {
					display: none;
				}
			}
		}
	}
</style>
