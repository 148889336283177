<template>
	<a
		:class="[`text-${color}`]"
		class="d-flex align-items-center gap-2 text-decoration-none"
		href="https://www.instagram.com/libreriaeimprentarivero/?hl=es"
		target="_blank"
		rel="noopener noreferrer"
	>
		<i
			v-if="showIcon"
			role="button"
			style="font-size: 1.2rem"
			class="pi pi-instagram"
		></i>
		<div v-if="showText">655 265 333</div>
	</a>
</template>

<script>
	export default {
		props: {
			showIcon: {
				type: Boolean,
				default: true,
			},
			showText: {
				type: Boolean,
				default: false,
			},
			color: {
				type: String,
				default: "white",
			},
		},
	};
</script>
