<template>
	<div class="custom-section-padding custom-container mx-auto">
		<h3>Política de privacidad</h3>

		<p><b>Propiedad de esta web</b></p>

		<p>
			En virtud de la Ley 34/2002, de 11 de julio, de Servicios de la
			Sociedad de la Información y de Comercio Electrónico, le comunicamos
			que la web que está visitando ubicada bajo el dominio
			<b>http://www.riverosl.es</b> pertenece a la entidad mercantil
			<b>Papelería e Imprenta Rivero, S.L.</b>, cuyos datos
			identificativos son los siguientes:
		</p>

		<ul>
			<li><b>Razón Social:</b> Papelería e Imprenta Rivero, S.L.</li>
			<li>
				<b>Dirección:</b> C/ General Vives, 60 35007 - Las Palmas de
				G.C.
			</li>
			<li><b>CIF:</b> B-35218767</li>
			<li>
				<div class="d-flex gap-2">
					<b>Teléfono:</b>
					<Phone color="dark" :showText="true" :showIcon="false" />
					-
					<Phone
						color="dark"
						:showText="true"
						:showIcon="false"
						phoneNumber="phone-2"
					/>
				</div>
			</li>
			<li>
				<div class="d-flex gap-2">
					<b>Email:</b>
					<Email color="primary" />
				</div>
			</li>
			<li><b>Dirección web:</b> http://www.riverosl.es</li>
		</ul>

		<p>Protección de datos de carácter personal</p>

		<p>
			En virtud de la Ley 15/99, Orgánica, de Protección de Datos de
			Carácter Personal (LOPD), se le comunica que sus datos de carácter
			personal que pudiera remitirnos vía correo electrónico formarán
			parte de un fichero informático, cuyo contenido es el de los
			visitantes de esta web en solicitud de información sobre los
			productos y/o servicios que esta Empresa publicita, siendo los
			destinatarios de la información, única y exclusivamente, el personal
			autorizado de esta Organización, con la única finalidad de atender
			los requerimientos de información de los potenciales o actuales
			clientes en relación a los productos y/o servicios comercializados.
		</p>

		<p>
			<b
				>Procedimientos de acceso, cancelación, oposición y/o
				rectificación</b
			>
		</p>

		<p>
			En relación a sus datos de carácter personal remitidos a estas
			Organización, se le informa que le asisten los derechos de acceso,
			cancelación, oposición y rectificación, pudiéndolos ejercitar ante
			la dirección postal arriba indicada, por correo ordinario o en
			persona, a través del procedimiento que a continuación se expone.
		</p>

		<p>
			Tendrá derecho a saber en qué estado están sus datos; a qué tipo de
			tratamiento están sujetos; de dónde se han obtenido; modificarlos si
			considerase no son correctos, o siéndolo inicialmente ya no reflejan
			la realidad actual por haber cambiado ésta, o incluso cancelarlos
			totalmente, siempre y cuando esto último no suponga la pervivencia
			de una deuda con la organización, previamente pactada, o sea
			consecuencia de situaciones de mera morosidad cuando el vínculo
			entre las partes haya sido no gratuito y sí motivado por la
			solicitud de una prestación de servicios mediante remuneración
			económica a la misma.
		</p>

		<p>
			Tampoco se podrán cancelar cuando su conservación obedezca, única y
			exclusivamente, al acatamiento y observancia de las leyes que sobre
			temas fiscales y mercantiles estén vigentes en cada momento a
			efectos de facturación y control internos.
		</p>

		<p>
			Para el ejercicio de los mencionados derechos, en concreto para su
			solicitud, se habrá de formular la misma al responsable del fichero
			mediante instancia en la que figuren los datos siguientes:
		</p>

		<ul>
			<li>
				Nombre y apellidos del afectado, adjuntando fotocopia del DNI (o
				de cualquier otro documento válido en derecho en orden a la
				identificación del solicitante). Para el caso de actuar en
				representación de alguien, o de alguna persona jurídica,
				fotocopia del documento que acredite tal representación
			</li>
			<li>Petición en que se basa o fundamenta la solicitud.</li>
			<li>Indicación de un domicilio a efectos de notificación.</li>
			<li>
				Documentos, en su caso, que acrediten el fundamento de la
				petición.
			</li>
			<li>Fecha y firma del solicitante.</li>
		</ul>

		<p>
			Para el envío de dicha solicitud habrá de usarse cualquier medio que
			pueda demostrar el envío y recepción de la solicitud.
		</p>

		<p><b>Propiedad Intelectual</b></p>

		<p>
			La citada empresa, titular y propietaria de este sitio web, lo es
			también de todos los derechos de propiedad intelectual e industrial
			que existan o puedan existir sobre el diseño, estructura y
			funcionalidad de este sitio web, incluyendo sus imágenes y
			organización de contenidos.
		</p>

		<p>
			Se prohibe la reproducción, distribución y/o comunicación pública,
			total o parcial, por cualquier medio sin la autorización previa y
			por escrito de esta entidad mercantil.
		</p>
	</div>
</template>

<script>
	import Phone from "@/components/web/partials/Phone.vue";
	import Email from "@/components/web/partials/Email.vue";

	export default {
		components: {
			Phone,
			Email,
		},
	};
</script>
