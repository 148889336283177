<template>
	<div class="custom-section-padding custom-container mx-auto">
		<div class="d-flex align-items-center flex-column text-center">
			<h2>Servicio integral al cliente</h2>
			<!-- <h5 class="text-muted">
				Material de oficina, material escolar, papelería, servicios de
				imprenta, diseño gráfico…
			</h5> -->
			<div class="row justify-content-center mt-2 gy-4">
				<div
					v-for="(c, index) in catalog"
					:key="index"
					class="col-12 col-md-6 col-lg-4"
				>
					<div class="px-5">
						<Product :product="c" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="custom-section-padding custom-container mx-auto">
		<div class="d-flex align-items-center flex-column text-center">
			<h2>Catálogo de productos</h2>
			<h5 class="text-muted">
				Material de oficina, material escolar, papelería, servicios de
				imprenta, diseño gráfico…
			</h5>
			<div class="row justify-content-center mt-2 gy-4">
				<div
					v-for="(product, index) in products"
					:key="index"
					class="col-12 col-md-6 col-lg-4"
				>
					<div class="px-5">
						<Product :product="product" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Product from "@/components/web/partials/Product.vue";

	export default {
		components: { Product },
		data() {
			return {
				catalog: [
					{
						name: "Copistería",
						image: require("@/assets/images/catalog/copisteria.png"),
					},
					{
						name: "Papelería de empresa",
						image: require("@/assets/images/catalog/papeleriaempresa.png"),
					},
					{
						name: "Imprenta",
						image: require("@/assets/images/catalog/imprenta.png"),
					},
				],
				products: [
					{
						name: "Escritura",
						image: require("@/assets/images/catalog/escritura.png"),
						url: "https://catalogo.riverosl.es/searchcat/3-escritura.html_?s=8568&o=searchEngine_b2c&p=1&seHeadOption=catB&elementByPage=48&flagVista=C&flagRecursiveTree=S&codCategory=3&descCategory=escritura",
					},
					{
						name: "Informática",
						image: require("@/assets/images/catalog/informatica.png"),
						url: "https://catalogo.riverosl.es/searchcat/2-informatica.html_?s=8568&o=searchEngine_b2c&p=1&seHeadOption=catB&elementByPage=48&flagVista=C&flagRecursiveTree=S&codCategory=2&descCategory=informatica",
					},
					{
						name: "Máquinas de oficina",
						image: require("@/assets/images/catalog/maquinas-oficina.png"),
						url: "https://catalogo.riverosl.es/searchcat/1-maquinas-de-oficina.html_?s=8568&o=searchEngine_b2c&p=1&seHeadOption=catB&elementByPage=48&flagVista=C&flagRecursiveTree=S&codCategory=1&descCategory=maquinas-de-oficina",
					},
					{
						name: "Ofertas",
						image: require("@/assets/images/catalog/ofertas.png"),
						url: "https://catalogo.riverosl.es/PStores?s=8568&o=searchEngine_b2c&p=1&flagOffer=on&seHeadOption=offer&elementByPage=48&flagVista=C&flagRecursiveTree=S",
					},
					{
						name: "Novedades",
						image: require("@/assets/images/catalog/novedades.png"),
						url: "https://catalogo.riverosl.es/PStores?s=8568&o=searchEngine_b2c&p=1&flagNovelty=on&seHeadOption=novelty&elementByPage=48&flagVista=C&flagRecursiveTree=S",
					},
					{
						name: "Outlet",
						image: require("@/assets/images/catalog/outlet.png"),
						url: "https://catalogo.riverosl.es/PStores?s=8568&o=searchEngine_b2c&p=1&flagOutlet=on&seHeadOption=outlet&elementByPage=48&flagVista=C&flagRecursiveTree=S",
					},
				],
			};
		},
	};
</script>
