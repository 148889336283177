<template>
	<div class="custom-section-padding custom-container mx-auto">
		<SectionTitle title="Servicios" />
		<div class="d-flex align-items-md-center flex-column mt-5">
			<p class="text-muted">
				Nuestra meta principal es dar a nuestros clientes un servicio
				integral que resuelva todas sus necesidades, ya sea particular o
				empresa, de artículos de papelería o impresión.
				<br />
				<br />
				Con el objetivo de ahorrarle desplazamientos innecesarios, nos
				desplazamos nosotros para llevarle el material que necesite y
				nos encargamos de asesorarle en sus problemas de impresión,
				pudiendo obtener la solución que mejor se adapte a sus
				necesidades y al mejor precio.
				<br />
				<br />
				Además de un asesoramiento personalizado y la posibilidad de
				tener un presupuesto sin compromiso, disponemos de un amplio
				listado de servicios:
			</p>
			<div class="col-10 mx-auto">
				<div class="row mt-3 gy-4">
					<div
						v-for="(service, index) in services"
						:key="index"
						class="col-12 col-md-6 col-lg-3"
					>
						<Service :service="service" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Service from "@/components/web/partials/Service.vue";
	import SectionTitle from "@/components/web/partials/SectionTitle.vue";

	export default {
		components: { Service, SectionTitle },
		data() {
			return {
				services: [
					{
						name: "Impresión de papelería de empresa",
						image: require("@/assets/images/services/primary/file.svg"),
					},
					{
						name: "Diseño de logotipos e identidad corporativa",
						image: require("@/assets/images/services/primary/circle.svg"),
					},
					{
						name: "Servicios de copistería, copia de planos, etc",
						image: require("@/assets/images/services/primary/scanner.svg"),
					},
					{
						name: "Encuadernaciones",
						image: require("@/assets/images/services/primary/file-2.svg"),
					},
					{
						name: "Sellos de caucho",
						image: require("@/assets/images/services/primary/stamp.svg"),
					},
					{
						name: "Envío a domicilio",
						image: require("@/assets/images/services/primary/delivery.svg"),
					},
					{
						name: "Imprenta",
						image: require("@/assets/images/services/primary/print.svg"),
					},
					{
						name: "Material de oficina",
						image: require("@/assets/images/services/primary/chair.svg"),
					},
				],
			};
		},
	};
</script>

<style>
	.material-symbols-outlined {
		font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
	}
</style>
