<template>
	<div class="service d-flex flex-column">
		<div
			class="image-section rounded overflow-hidden d-flex justify-content-center align-items-center"
		>
			<img :src="service.image" />
		</div>
		<div class="text-center py-3">
			<h6>{{ service.name }}</h6>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			service: {
				type: Object,
				required: true,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.service {
		position: relative;

		.image-section {
			aspect-ratio: 1;
			transition: $transition;
			cursor: pointer;
			background-color: #e5e5e5;

			img {
				width: 60%;
				object-fit: contain;
				transition: $transition;
			}
		}

		&:hover {
			.image-section {
				background-color: #d5d5d5;

				img {
					transform: scale(1.02);
				}
			}
		}
	}

	@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		.service {
			.image-section {
				img {
					width: 50%;
				}
			}
		}
	}
</style>
