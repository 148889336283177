<template>
	<a
		class="product d-flex flex-column text-decoration-none"
		:class="{ 'text-dark': !product.url }"
		:href="product.url"
		target="_blank"
	>
		<div class="image-section" :class="{ 'no-animation': !product.url }">
			<img :src="product.image" />
		</div>
		<div
			class="description-section d-flex justify-content-center align-items-center flex-column p-3"
		>
			<h5 class="text-center">{{ product.name }}</h5>
		</div>
	</a>
</template>

<script>
	export default {
		props: {
			product: {
				type: Object,
				required: true,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.product {
		position: relative;

		.image-section {
			aspect-ratio: 1;
			transition: $transition;
			position: relative;
			overflow: hidden;
			border-radius: 100rem;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				transition: $transition;
			}

			&:not(.no-animation) {
				cursor: pointer;

				&:hover {
					background-color: #d5d5d5;

					img {
						transform: scale(1.02);
					}
				}
			}
		}
	}
</style>
