<template>
	<a
		:class="[`text-${color}`]"
		class="text-decoration-none"
		href="mailto:info@riverosl.es"
		target="_blank"
		rel="noopener noreferrer"
	>
		info@riverosl.es
	</a>
</template>

<script>
	export default {
		props: {
			color: {
				type: String,
				default: "white",
			},
		},
	};
</script>
