<template>
	<div class="header">
		<swiper
			:modules="modules"
			:autoplay="{
				delay: 4000,
				disableOnInteraction: false,
			}"
			:pagination="{
				type: 'progressbar',
			}"
			:loop="true"
			:slides-per-view="1"
			class="h-100"
		>
			<swiper-slide
				v-for="(slide, index) in slides"
				:key="index"
				class="slide d-flex justify-content-center align-items-center h-100 bg-primary"
				:style="{ backgroundImage: `url(${slide.backgroundImage})` }"
			>
				<div class="slide-content custom-container text-white">
					<div class="slide-content-subtitle rounded">
						{{ slide.subtitle }}
						<span>{{ slide.subtitleSpan }}</span>
					</div>
					<div class="slide-content-title">{{ slide.title }}</div>
					<Button
						v-if="slide.buttonLabel"
						:label="slide.buttonLabel"
						class="mt-3"
						@click="action(slide.action)"
					/>
				</div>
			</swiper-slide>
		</swiper>
		<div class="go-down" role="button" @click="$helper.scrollToStart(true)">
			<i class="pi pi-chevron-down"></i>
		</div>
	</div>
</template>

<script>
	// Import Swiper Vue.js components
	import { Swiper, SwiperSlide } from "swiper/vue";
	import { Autoplay, Pagination } from "swiper/modules";

	// Import Swiper styles
	import "swiper/css";
	import "swiper/css/pagination";

	import Button from "primevue/button";

	export default {
		components: {
			Swiper,
			SwiperSlide,
			Button,
		},
		data() {
			return {
				modules: [Autoplay, Pagination],
				slides: [
					{
						title: "Papelería e Imprenta",
						subtitle: "Juntos desde ",
						subtitleSpan: "1946",
						// buttonLabel: "Accede a nuestro catálogo",
						// action: "products",
						backgroundImage: require("@/assets/images/header/slide-1.jpg"),
					},
					{
						title: "Agendas 2025",
						subtitle: "Ya a la ",
						subtitleSpan: "venta",
						backgroundImage: require("@/assets/images/header/slide-2.jpg"),
					},
				],
			};
		},
		methods: {
			action(action) {
				if (action === "products") {
					window.open("https://catalogo.riverosl.es/", "_blank");
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.header {
		height: 100vh;
		height: calc(var(--vh, 1vh) * 80);
		position: relative;

		.slide {
			background-size: cover;
			background-position: center;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.5);
			}

			.slide-content {
				position: relative;
				z-index: 1;

				.slide-content-title {
					font-size: 5rem;
					font-weight: bold;
				}

				.slide-content-subtitle {
					font-size: 2.5rem;
					font-weight: bold;
					background-color: $primary;
					color: white;
					padding: 0 1rem;
					width: fit-content;
				}
			}
		}

		:deep(.swiper-pagination) {
			bottom: 1rem;
			right: 1rem;
			left: unset;
			top: unset;
			width: 20rem;

			.swiper-pagination-progressbar-fill {
				background-color: white;
			}
		}

		.go-down {
			position: absolute;
			bottom: 1rem;
			left: 50%;
			transform: translateX(-50%);
			color: white;
			z-index: 1;
			animation: animated 2s infinite;

			i {
				font-size: 1.5rem;
			}

			@keyframes animated {
				0% {
					transform: translateX(-50%) translateY(0);
					opacity: 1;
				}

				100% {
					transform: translateX(-50%) translateY(1rem);
					opacity: 0;
				}
			}
		}

		@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			.slide {
				.slide-content {
					.slide-content-title {
						font-size: 3rem;
					}

					.slide-content-subtitle {
						font-size: 1.5rem;
					}
				}
			}

			:deep(.swiper-pagination) {
				left: 1rem;
				width: unset;
			}

			.go-down {
				bottom: 3rem;
			}
		}
	}
</style>
