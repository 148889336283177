<template>
	<Dialog
		v-model:visible="visible"
		modal
		:style="{ width: '40rem', maxWidth: '90vw' }"
	>
		<div class="d-flex flex-column align-items-start gap-3">
			<div>
				Este sitio web utiliza cookies propias y de terceros. Si
				continúa accediendo a nuestra página, entendemos que nos otorga
				su consentimiento expreso para su uso. Puedes leer más en
				nuestra
				<router-link to="politica-de-cookies"
					>Política de cookies</router-link
				>.
			</div>
			<div class="w-100 d-flex justify-content-end gap-3">
				<Button label="Rechazar" @click="denyCookies"></Button>
				<Button label="Aceptar" @click="acceptCookies"></Button>
			</div>
		</div>
	</Dialog>
</template>

<script>
	export default {
		data() {
			return {
				visible: false,
			};
		},
		mounted() {
			if (localStorage.getItem("cookiesAccepted")) return;

			this.visible = true;
		},
		methods: {
			acceptCookies() {
				this.visible = false;
				localStorage.setItem("cookiesAccepted", "true");
			},
			denyCookies() {
				this.visible = false;
				localStorage.setItem("cookiesAccepted", "false");
			},
		},
	};
</script>
