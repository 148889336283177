<template>
	<div class="go-catalog-button d-lg-none">
		<a href="https://catalogo.riverosl.es/" target="_blank">
			<button
				class="button d-flex align-items-center justify-content-center"
				@click="goCatalog"
			>
				<div class="pi pi-shopping-cart"></div>
			</button>
		</a>
	</div>
</template>

<script>
	export default {
		methods: {
			goCatalog() {
				console.log("Hola");
			},
		},
	};
</script>

<style lang="scss" scoped>
	.go-catalog-button {
		position: fixed;
		bottom: 1rem;
		left: 1rem;
		z-index: 9;
		border-radius: 100%;
		box-shadow: $smooth-box-shadow;
		transition: $transition;

		.button {
			border: 0;
			border-radius: 10rem;
			width: 3.5rem;
			aspect-ratio: 1;
			background-color: $primary;
			color: white;

			.pi {
				font-size: 1.2rem;
			}
		}
	}
</style>
