<template>
	<div class="bg-secondary text-white h2 text-center py-4 px-3">
		{{ title }}
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				required: true,
			},
		},
	};
</script>
