<template>
	<div class="custom-container mx-auto">
		<div class="d-flex align-items-center text-center flex-column">
			<h4 class="text-primary fw-bold mb-3">Bienvenid@ a Rivero SL</h4>
			<h3 class="text-dark fw-bold">
				Somos una empresa de papelería e imprenta desde 1946 con una
				gran experiencia y especialización en nuestro sector.
			</h3>
		</div>
	</div>
</template>
