<template>
	<div class="custom-section-padding custom-container mx-auto">
		<SectionTitle title="Contacto" />
		<div class="about-us d-flex flex-wrap mt-5">
			<div class="col-12 col-lg-6">
				<div class="left-section rounded overflow-hidden">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m0!4v1508490996714!6m8!1m7!1s0rxCgwUdR9RxyPIkzBqWbA!2m2!1d28.1391161135428!2d-15.43134611486704!3f66.13410955503232!4f1.7976184039724217!5f0.7820865974627469"
						width="100%"
						height="100%"
						frameborder="0"
						style="border: 0"
						allowfullscreen=""
					></iframe>

					<!-- <img
						class="rounded image"
						src="@/assets/images/contact/map.jpg"
						width="100%"
					/> -->
				</div>
			</div>
			<div class="col-12 col-lg-6 mt-4 mt-lg-0">
				<div
					class="right-section d-flex justify-content-center align-items-start flex-column h-100 p-md-5"
				>
					<div class="h2 mb-4">Contacte con nosotros</div>
					<p class="text-small mb-0">
						Le agradecemos que nos haga llegar sus comentarios y/o
						sugerencias rellenando el siguiente formulario. Nos
						pondremos en contacto con usted en la mayor brevedad
						posible.
					</p>

					<form
						@keydown="$event.key === 'Enter' ? save() : null"
						class="mt-3"
					>
						<div class="row gy-3">
							<div class="col-12">
								<InputText
									ref="name"
									label="Nombre"
									:disabled="disabled"
									:error="form.errors.get('name')"
									@change-value="
										(value) => (form.name = value)
									"
									:showLabel="false"
								/>
							</div>
							<div class="col-12">
								<InputText
									ref="company"
									label="Empresa"
									:disabled="disabled"
									:error="form.errors.get('company')"
									@change-value="
										(value) => (form.company = value)
									"
									:showLabel="false"
								/>
							</div>
							<div class="col-12">
								<InputText
									ref="email"
									label="Email"
									:disabled="disabled"
									:error="form.errors.get('email')"
									@change-value="
										(value) => (form.email = value)
									"
									:showLabel="false"
								/>
							</div>
							<div class="col-12">
								<InputText
									ref="phone"
									label="Teléfono de contacto"
									:disabled="disabled"
									:error="form.errors.get('phone')"
									@change-value="
										(value) => (form.phone = value)
									"
									:showLabel="false"
								/>
							</div>
							<div class="col-12">
								<InputText
									ref="subject"
									label="Asunto"
									:disabled="disabled"
									:error="form.errors.get('subject')"
									@change-value="
										(value) => (form.subject = value)
									"
									:showLabel="false"
								/>
							</div>
							<div class="col-12">
								<TextArea
									ref="messageText"
									label="Texto del mensaje"
									:disabled="disabled"
									:error="form.errors.get('messageText')"
									@change-value="
										(value) => (form.messageText = value)
									"
									:showLabel="false"
								/>
							</div>
							<div class="col-12">
								<CheckBox
									ref="acceptTerms"
									label="Doy mi consentimiento para el tratamiento de mis datos personales."
									:disabled="disabled"
									:error="form.errors.get('acceptTerms')"
									@change-value="
										(value) => (form.acceptTerms = value)
									"
									:binary="true"
								/>
							</div>
							<div class="col-12 small">
								He leído y acepto el
								<router-link to="aviso-legal"
									>aviso legal</router-link
								>
								y la
								<router-link to="politica-de-privacidad"
									>política de privacidad</router-link
								>
								de datos.
							</div>
							<div class="col-12">
								<Button
									@click="save()"
									label="Enviar"
									:disabled="form.busy"
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations } from "vuex";
	import SectionTitle from "@/components/web/partials/SectionTitle.vue";

	export default {
		components: {
			SectionTitle,
		},
		data: () => ({
			form: new Form(),
			disabled: false,
		}),
		methods: {
			...mapActions(["sendForm"]),
			...mapMutations(["changeSuccessToast"]),
			save() {
				const url = `/contact-form`;

				this.sendForm({
					method: "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
					showToast: false,
				}).then((response) => {
					console.log("🚀 ~ response:", response);
					if (response?.status === 200) {
						this.clearForm();
						this.changeSuccessToast(
							"Formulario enviado correctamente."
						);
					}
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.about-us {
		.left-section {
			width: 100%;
			height: 100%;

			iframe {
				min-height: 20rem;
			}
		}

		.right-section {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				width: 3rem;
				aspect-ratio: 3/4;
				border-top: 1px solid $primary;
				border-right: 1px solid $primary;
				z-index: 1;
			}

			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 3rem;
				aspect-ratio: 4/3;
				border-bottom: 1px solid $primary;
				border-right: 1px solid $primary;
				z-index: 1;
			}

			.subtitle {
				letter-spacing: 0.1rem;
				word-spacing: 0.2rem;
			}

			p {
				font-size: 0.9rem;
			}
		}

		@media (min-width: $mobile-min-width) and (max-width: $tablet-max-width) {
			.right-section {
				&::after {
					display: none;
				}

				&::before {
					display: none;
				}
			}
		}
	}
</style>
